import $ from "jquery";

$(() => {
    const $newsletter = $(".newsletter");
    
    $newsletter.find("form").form();
    
    /**
     * do email validation
     */
    $newsletter.find("input[type=email]").on("input, change", function (e) {
        if (e.target.setCustomValidity) {
            if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+.[a-zA-Z0-9]{2,13}$/.test($(e.target).val()))
                e.target.setCustomValidity("");
            else
                e.target.setCustomValidity($(e.target).parent().find(".invalid-feedback").text());
        }
    });
    
    /**
     * some cryptic counting up (mailingworks)
     */
    setInterval(() => {
        $newsletter.find("input[name=ic]").val((i, val) =>
            isNaN(val = parseInt(val)) ? 0 : val + 17,
        );
    }, 1000);
});
